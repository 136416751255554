<template>
  <v-container>
    <div class="text-center">
      {{ $t('hpLowerDisclaimer') }}
      <router-link :to="VUE_APP_URL_LEGAL_PRIVACY">{{ $t('dataprivacy') }}</router-link
      >, <router-link :to="VUE_APP_URL_LEGAL_TOS">{{ $t('TOS') }}</router-link> {{ $t('hpLowerDisclaimer2') }}
      <router-link :to="VUE_APP_URL_ABOUT_THIS_TOOL">{{ $t('Disclaimer') }}</router-link>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'LowerPartHomepage',
  computed: {
    VUE_APP_URL_ABOUT_THIS_TOOL: () => process.env.VUE_APP_URL_ABOUT_THIS_TOOL,
    VUE_APP_URL_LEGAL_PRIVACY: () => process.env.VUE_APP_URL_LEGAL_PRIVACY,
    VUE_APP_URL_LEGAL_TOS: () => process.env.VUE_APP_URL_LEGAL_TOS,
  },
};
</script>
