<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12">
        <h3 class="headline text-center justify-center mb-6">Frequently Asked Questions</h3>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="headline" key="1"> What is a cookie scanner? </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-6 my-3">
              A cookie scanner is a tool that analyzes cookies, which are small pieces of data stored on a computer by a web
              browser. The scanner looks for cookies set by your website. More advanced cookie scannes like cookie-scanner.com
              analyzes for more privacy and GDPR relevant things, like: third party scripts, localStorage and tracking requests.
            </v-expansion-panel-content> </v-expansion-panel
          ><v-expansion-panel>
            <v-expansion-panel-header class="headline" key="2"> How does a cookie scanner work? </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-6 my-3">
              A cookie scanner visits your webpage and analyzes the behaviour of all the javascripts and the website itself. At
              the end you will get a summary report what cookies your website is using. This helps you making your webpage
              complient with privacy regulations like GDPR or CCPA.
            </v-expansion-panel-content> </v-expansion-panel
          ><v-expansion-panel>
            <v-expansion-panel-header class="headline" key="3"> Why do I need to scan cookies? </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-6 my-3">
              To be compliant with the privacy regulations of many countries you need to understand the behaviour of your website.
              For example: what cookies are set? What tracking is used? What third party domains are used? And in best case you do
              the same check scheduled, so that you get an alert, if something changes and you have to adjust your privacy policy.
              This can help you to prevent fines for not being compliant.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomepageFAQs',
  computed: {},
};
</script>
