<template>
  <v-container>
    <v-row class="text-center justify-center ma-4">
      <v-icon class="display-1" color="primary" dark>{{ mdiFeatureSearch }}</v-icon>
    </v-row>
    <div class="text-center justify-center">
      <p class="headline">{{ $t(`hpHeadline`) }}</p>
      <p>
        {{ $t('hpDescription') }}
      </p>
    </div>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiFeatureSearch } from '@mdi/js';

export default {
  name: 'HeadlineStart',
  data: () => ({ mdiFeatureSearch }),
  computed: {
    VUE_APP_URL_ABOUT_THIS_TOOL: () => process.env.VUE_APP_URL_ABOUT_THIS_TOOL,
  },
};
</script>
