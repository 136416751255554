const queryCheck = s => document.createDocumentFragment().querySelector(s);

function isSelectorValid(selector) {
  if (!selector) {
    return false;
  }

  try {
    queryCheck(selector);
  } catch {
    return false;
  }
  return true;
}

export default isSelectorValid;
