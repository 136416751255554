<template>
  <v-container>
    <v-form v-model="valid" method="post" id="crawlRequestForm" @submit.prevent="processForm()">
      <v-row>
        <v-col cols="3" sm="2" md="2" lg="2">
          <v-subheader>Start Url</v-subheader>
        </v-col>
        <v-col cols="9" sm="10" md="10" lg="10">
          <v-text-field
            solo
            :hint="$t('hintUrlToCrawl')"
            :label="$t('labelUrlToCrawl')"
            color="primary"
            v-model="urlToCrawl"
            name="urlToCrawl"
            :rules="urlRules"
            @keypress.enter="show"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="2" md="2" lg="2">
          <v-subheader>Number of pages to crawl</v-subheader>
        </v-col>
        <v-col cols="9" sm="10" md="10" lg="10">
          <v-text-field
            :hint="pageCrawlHint"
            :label="$t('labelpagesToCrawl')"
            color="primary"
            v-model="pagesToCrawl"
            name="pagesToCrawl"
            type="number"
            :error-messages="allowedPagesToCrawl"
            required
          >
          </v-text-field> </v-col
      ></v-row>
      <v-row>
        <v-col cols="3" sm="2" md="2" lg="2">
          <v-subheader>Installed Banner</v-subheader>
        </v-col>
        <v-col cols="9" sm="10" md="10" lg="10">
          <v-combobox
            v-model="comboBoxUsedBanner"
            :items="consentBannerItems"
            label="Installed Banner"
            :hint="$t('hintGAGroupBy')"
            solo
            persistent-hint
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row v-if="comboBoxUsedBanner.value === 'custom'">
        <v-col cols="3" sm="2" md="2" lg="2">
          <v-subheader>CSS Selector</v-subheader>
        </v-col>
        <v-col cols="9" sm="10" md="10" lg="10">
          <v-text-field
            :hint="$t('hintCSSSelectorOfConsentButton')"
            label="CSS Selector"
            color="primary"
            v-model="optInSelector"
            name="optInSelector"
            :rules="optInSelectorRules"
            @keypress.enter="show"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="2" md="2" lg="2">
          <v-subheader>Click Banner to Opt-in</v-subheader>
        </v-col>
        <v-col cols="9" sm="10" md="10" lg="10">
          <v-checkbox v-model="clickTheBanner" :disabled="optinClick"></v-checkbox>
        </v-col> </v-row
      ><v-btn class="mb-5" @click="toggleAdvancedSettings()" text plain color="primary"
        >advanced settings <v-icon>{{ expandStatusIcon }}</v-icon></v-btn
      >
      <v-expansion-panels v-model="advancedSettingsOpen" flat tile class="ma-0 pa-0">
        <v-expansion-panel>
          <v-expansion-panel-content class="ma-0 pa-0">
            <v-row v-if="licenseData.allowedModules.length > 1">
              <v-col cols="3" sm="2" md="2" lg="2">
                <v-subheader>Target Crawler</v-subheader>
              </v-col>
              <v-col cols="9" sm="10" md="10" lg="10">
                <v-combobox
                  v-model="comboBoxTargetCrawler"
                  :items="licenseData.allowedModules"
                  label="Target Crawler"
                  solo
                  persistent-hint
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row v-if="licenseData.allowedModules.length > 1">
              <v-col cols="3" sm="2" md="2" lg="2">
                <v-subheader>Block network request by category</v-subheader>
              </v-col>
              <v-col cols="9" sm="10" md="10" lg="10">
                <v-combobox
                  v-model="comboBoxProviderCatsToBlockRequest"
                  :items="catsForRequestBlocking"
                  label="Nothing will be blocked"
                  solo
                  persistent-hint
                  multiple
                  hint="Often you do not want to see the crawler hits in your analytics tools. If you choose 'Analytics', the crawler will prevent that a tracking hit is sent to most common analytics solutions, e.g. GA, Adobe"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="2" md="2" lg="2" class="mt-5">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-subheader v-bind="attrs" v-on="on"
                      >JavaScript to evaluate on every page<v-icon>{{ mdiInformationOutline }}</v-icon></v-subheader
                    >
                  </template>
                  <span
                    >Add JavaScript which will be evaluated on every crawled page. The return value for each page will be stored
                    and you see a summary statistic of the values. Max length of return value is 600 chars.</span
                  >
                </v-tooltip>
              </v-col>
              <v-col cols="9" sm="10" md="10" lg="10">
                <v-textarea
                  outlined
                  v-model="fieldJsToEvaluate"
                  label="JavaScript"
                  value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                  hint="Must a return a number, boolean or string with a maximum length of 600."
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col class="d-flex justify-center">{{ $t('Notifications can be configured on the crawl result page.') }}</v-col></v-row
      >
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn color="primary" @click.prevent="processForm()">{{ $t('startanalysis') }} </v-btn></v-col
        ></v-row
      >
    </v-form>
  </v-container>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiChevronUp, mdiChevronDown, mdiInformationOutline } from '@mdi/js';
import router from '../router/index';
import { addProtocolToUrl, isValidURL } from '../utils/urls';
import isSelectorValid from '../utils/generalValidations';
import { sendCrawlRequest } from '../utils/apiData';

export default {
  data() {
    return {
      valid: true,
      step: 1,
      consentOption: 'noOptin',
      dataLayerName: 'dataLayer',
      urlToCrawl: '',
      urlRules: [v => isValidURL(v) || 'Please enter a valid URL, e.g. https://beautiful-cookie-banner.com'],
      pagesToCrawl: 3,
      crawlsInParallel: 4,
      optInSelector: '',
      catsForRequestBlocking: ['Analytics', 'UX Testing', 'Tag Manager', 'Visitor Identification', 'Marketing'],
      comboBoxProviderCatsToBlockRequest: [],
      optInSelectorRules: [v => isSelectorValid(v) || 'Please enter a correct css selector.'],
      optInId: '',
      optInIdRules: [v => !/[^\w-]/.test(v) || 'Please enter a correct id, e.g. my-id'],
      showLicenseInfoDialog: false,
      onlyCrawlStartUrlSubDirs: false,
      gaGroupBy: [],
      clickTheBanner: false,
      comboBoxTargetCrawler: 'cookieCrawler',
      fieldJsToEvaluate: 'function doIt(){\n\treturn "halloWorld";\n}',
      advancedSettingsOpen: -1,
      mdiChevronDown,
      mdiChevronUp,
      mdiInformationOutline,
    };
  },
  components: {},
  mounted() {
    let requestFormConfig = localStorage.getItem('requestFormConfig');
    if (requestFormConfig) {
      requestFormConfig = JSON.parse(requestFormConfig);
      this.urlToCrawl = requestFormConfig.urlToCrawl || '';
      this.optInSelector = requestFormConfig.optInSelector || '';
      if (this.licenseData.allowedModules.length > 1) {
        this.comboBoxTargetCrawler = requestFormConfig.targetCrawler;
      }
      if (this.userData.loggedIn === true) {
        this.comboBoxProviderCatsToBlockRequest = requestFormConfig.providerCatsToBlockRequest;
      }
      if (requestFormConfig.jsToEvaluate) {
        this.fieldJsToEvaluate = requestFormConfig.jsToEvaluate;
      }
      if (typeof requestFormConfig.clickTheBanner !== 'undefined') {
        this.clickTheBanner = requestFormConfig.clickTheBanner;
      }
    }
    this.advancedSettingsOnOpen();
  },
  computed: {
    expandStatusIcon() {
      if (this.advancedSettingsOpen === 0) {
        return this.mdiChevronUp;
      }
      return this.mdiChevronDown;
    },
    defaultJsToEvaluateValue() {
      return `function doIt(){\n\treturn "halloWorld";\n}`;
    },
    consentBannerItems() {
      return this.comboBoxUsedBannerItems.map(item => ({ text: item.name, value: item.id }));
    },
    VUE_APP_URL_ABOUT_THIS_TOOL() {
      return process.env.VUE_APP_URL_ABOUT_THIS_TOOL;
    },
    licenseData() {
      if (this.$store.getters['user/userData'].licenses && this.$store.getters['user/userData'].licenses[0]) {
        return this.$store.getters['user/userData'].licenses[0];
      }
      return {};
    },
    allowedPagesToCrawl() {
      if (this.pagesToCrawl > this.licenseData.allowed_crawls_per_request && this.userData.loggedIn === false) {
        return `Create a free acount to to crawl more than ${this.licenseData.allowed_crawls_per_request} pages at once.`;
      }
      if (this.pagesToCrawl > this.licenseData.allowed_crawls_per_request && this.userData.loggedIn === true) {
        return `Contact support@cookie-scanner.com to crawl more than ${this.licenseData.allowed_crawls_per_request} pages at once.`;
      }
      if (this.pagesToCrawl > this.licenseData.allowed_crawls_per_request) {
        return `You are only allowed to crawl ${this.licenseData.allowed_crawls_per_request} pages at once.`;
      }
      if (this.pagesToCrawl < 1) {
        return `We only know how to crawl more then 0 pages.`;
      }
      return undefined;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    optinClick() {
      return this.comboBoxUsedBanner.value === 'noBanner';
    },
    crawlsInParallelHint() {
      return `Be careful: a high number means your crawl is finished earlier, BUT it might put SERIOUS load on your server.`;
    },
    pageCrawlHint() {
      if (this.userData.loggedIn === false) {
        return `It will crawl the number of pages in a random order, starting with the page you specified as 'Start Url'.`;
      }
      return undefined;
    },
  },
  methods: {
    advancedSettingsOnOpen() {
      if (this.fieldJsToEvaluate !== this.defaultJsToEvaluateValue) {
        this.advancedSettingsOpen = 0;
      }

      if (this.pagesToCrawl !== 3) {
        this.advancedSettingsOpen = 0;
      }
    },
    toggleAdvancedSettings() {
      if (this.advancedSettingsOpen === 0) {
        this.advancedSettingsOpen = -1;
        return;
      }
      this.advancedSettingsOpen = 0;
    },
    setShowLicenseDialog(message) {
      this.showLicenseInfoDialog = message;
    },
    async processForm() {
      this.$store.commit('message/setShowMessage', false);
      if (!this.urlToCrawl) {
        this.sendError(`Please enter an url, it is mandatory.`);
        return;
      }

      if (this.comboBoxUsedBanner.value === 'custom' && !isSelectorValid(this.optInSelector)) {
        this.sendError(`Please enter a valid css selector.`);
        return;
      }

      this.urlToCrawl = addProtocolToUrl(this.urlToCrawl);
      this.$store.commit('dialog/setName', {
        name: 'DialogLoadingScreen',
        data: { pagesToCrawl: this.pagesToCrawl, startUrl: this.urlToCrawl },
      });

      let cleanedJSToEvaluate = '';
      if (this.fieldJsToEvaluate !== this.defaultJsToEvaluateValue) {
        cleanedJSToEvaluate = this.fieldJsToEvaluate;
      }

      localStorage.setItem(
        'requestFormConfig',
        JSON.stringify({
          urlToCrawl: this.urlToCrawl,
          optInSelector: this.optInSelector,
          targetCrawler: this.comboBoxTargetCrawler,
          providerCatsToBlockRequest: this.comboBoxProviderCatsToBlockRequest,
          jsToEvaluate: cleanedJSToEvaluate,
          clickTheBanner: this.clickTheBanner,
        })
      );

      const crawlerConfigs = {
        url: this.urlToCrawl,
        consentSelector: [this.optInSelector],
        clickTheBanner: this.clickTheBanner,
        numberOfPagesToCrawl: this.pagesToCrawl,
        crawlsInParallel: this.crawlsInParallel,
        targetCrawler: this.comboBoxTargetCrawler,
        providerCatsToBlockRequest: this.comboBoxProviderCatsToBlockRequest,
        jsToEvaluate: cleanedJSToEvaluate,
      };

      const cri = await sendCrawlRequest(crawlerConfigs);
      if (!cri.success) {
        this.$store.commit('dialog/setShowDialog', false);

        if (cri.data.messageId === '24HDOMAINLIMITEXCEEDED') {
          const { hostname } = new URL(this.urlToCrawl);
          this.sendError(`You have been redirected to the last result for this domain: ${cri.data.message}`);
          router.push({ name: 'SummaryReporting', params: { hostnameToCrawl: hostname } });
          return;
        }
        this.sendError(`${cri.data.message}`);
        return;
      }
      const { hostname } = new URL(this.urlToCrawl);
      router.push({ name: 'SummaryReporting', params: { hostnameToCrawl: hostname }, query: { ucrid: cri.data } });
    },
    sendError(message) {
      this.$store.commit('message/setMessage', { color: 'error', message: `${message}` });
    },
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
