<template>
  <v-container fluid>
    <v-card class="pa-xs-0 pa-sm-4 pa-md-4 pa-lg-6">
      <HeadlineStart />
      <SubmitRequestForm @message="messageProxy" />
      <LowerPartHomepage />
    </v-card>
    <v-card class="pa-xs-0 pa-sm-4 pa-md-4 pa-lg-6 mt-6">
      <HomepageInfos />
    </v-card>
    <v-card class="pa-xs-0 pa-sm-4 pa-md-4 pa-lg-6 mt-6">
      <HomepageFAQs />
    </v-card>
  </v-container>
</template>

<script>
import SubmitRequestForm from '../components/SubmitRequestForm.vue';
import HeadlineStart from '../components/HeadlineStart.vue';
import HomepageInfos from '../components/HomepageInfos.vue';
import LowerPartHomepage from '../components/LowerPartHomepage.vue';
import HomepageFAQs from '../components/HomepageFAQs.vue';

export default {
  name: 'HomePage',
  components: {
    SubmitRequestForm,
    HeadlineStart,
    HomepageInfos,
    LowerPartHomepage,
    HomepageFAQs,
  },
  data: () => ({}),
  methods: {
    messageProxy(message) {
      this.$emit('message', message);
    },
  },
  metaInfo: {
    title: 'Free Cookie Scanner: Check your website for hidden trackers, storage, cookies and more.',
    decription:
      'Free cookie scanner checks for cookies, trackers, if your cookie banner is working, third party requests and localStrorage. No login or e-mail required.',
  },
};
</script>
