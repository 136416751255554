<template>
  <v-container class="">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="pa-2" outlined tile elevation="0" style="border: #fff">
          <div class="text-center justify-center">
            <h3 class="headline">Our features and benefits</h3>
            <p>
              This cookie scanner uses advanced tecnology to search a website for cookies, tracking pixel and more. It uses a real
              browser and exceutes all javascript on that page. Because of its advanced crawling techology it is able to crawl
              multiple pages of complex websites.
            </p>
          </div></v-card
        >
      </v-col>
    </v-row>
    <v-row no-gutters
      ><v-col cols="12" xs="12" md="4">
        <v-card outlined class="mt-6 mr-6" flat style="border: #fff">
          <v-card-text>
            <div></div>
            <h6 class="text-h5 text--primary">No e-mail required</h6>
            <div class="text--primary">
              You do not need to create an account or share you e-mail adress. We understand that sharing your e-mail adress is a
              sensitive matter. This is why you get your cookie scanner report without the need of providing your e-mail. When you
              choose to create an account you get additional benefits.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="4">
        <v-card outlined class="mt-6 mr-6" style="border: #fff">
          <v-card-text>
            <h6 class="text-h5 text--primary">Free to scan</h6>
            <div class="text--primary">
              You can always scan your webpage for cookies for free and see what cookies localstorage and trackingpixel you have
              on your website. We scan three pages for free without an account and five with an account.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="4">
        <v-card outlined class="mt-6" style="border: #fff">
          <v-card-text>
            <h6 class="text-h5 text--primary">Fast scanning</h6>
            <div class="text--primary">
              It is annoying being forced in a queue and wait for an hour just to get the test cookie scan result for you website.
              This is why our tool scans your website really fast. We did our best to increase the scanning speed even for free
              scans.
            </div>
          </v-card-text>
        </v-card>
      </v-col></v-row
    >
    <v-row no-gutters>
      <v-col cols="12" xs="12" md="4">
        <v-card class="pa-2 mt-6 mr-6" outlined tile elevation="0" style="border: #fff">
          <div>
            <h6 class="headline">Cookie & 3rd party request source report</h6>
            <p>
              We provide you with a summary and a reporting per page. With the per page cookie and tracking report you get a
              detailed "Cookie & JS Source Report". That means that the report shows you the source of a cookie and if it is a
              server cookie or a javascript cookie. For third party request we show you which javascript initiated the request.
              This increases troubleshooting speed for you a lot.
            </p>
          </div></v-card
        >
      </v-col>
      <v-col cols="12" xs="12" md="4">
        <v-card class="pa-2 mt-6" outlined tile elevation="0" style="border: #fff">
          <div>
            <h6 class="headline">Cookie Banner status report</h6>
            <p>
              We check if your cookie banner is working. You can get a report without accepting all your cookies or with accepting
              cookies. We check if the banner is present on all the pages of your website.
            </p>
          </div></v-card
        >
      </v-col>
      <v-col cols="12" xs="12" md="4">
        <v-card class="pa-2 mt-6" outlined tile elevation="0" style="border: #fff">
          <div>
            <h6 class="headline">Change monitoring & notifications</h6>
            <p>Get notified if the result of your website scan changes.</p>
          </div></v-card
        >
      </v-col>
    </v-row>
    <v-row no-gutters> </v-row>
    <span class="d-flex justify-center"> </span>
  </v-container>
</template>

<script>
export default {
  name: 'HomepageInfos',
  computed: {},
};
</script>
